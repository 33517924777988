import './index.css';

import React from 'react';
import ReactDOM from 'react-dom';
import { Playground } from './components/playground';
ReactDOM.render(
	<Playground></Playground>
	,
	document.getElementById('root')
);
